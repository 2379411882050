import * as request from '@/utils/request'

export default {
  /**
   * getPublicationList
   * @description 刊例列表列表
   * @param {*} data
   * @returns
   */
  getPublicationList(data) {
    return request.post('/plc/list', data)
  },
  /**
   * 刊例详情
   * @param id
   * @returns {Promise}
   */
  getPublicationInfo(id) {
    return request.post('/plc/get_info', { id: id })
  },
  /**
   * 根据本期刊例ID获取其上期的刊例数据
   * @param currentId
   * @returns {Promise}
   */
  getPublicationLast(currentId) {
    return request.post('/plc/get_last', { id: currentId })
  },
  /**
   * 刊例调整说明的文章ID
   *
   * @returns {Promise}
   */
  orderPublication(ids) {
    return request.post('/plc/order', { ids: ids })
  },
  /**
   * 刊例排序更新
   *
   * @returns {Promise}
   */
  getPublicationArticleID() {
    return request.post('/plc/get_article_id', {})
  },
  /**
   * 获取刊例导出的数据
   * @param year
   * @param month
   * @returns {Promise}
   */
  getExportPublicationAllData(year, month) {
    return request.post('/plc/export_data', { year: year, month: month })
  },

  /**
   * 导出刊例
   * @param year
   * @param month
   * @returns {Promise}
   */
  exportPublicationAsExcel(year, month) {
    return request.postBlob('/plc/export', { year: year, month: month })
  },
  /**
   * 请求golang服务-获取excel文件
   * @param data
   * @returns {Promise}
   */
  downloadPublicationExcelByData(data) {
    return request.postBlob('/tools_api/module/spreadSheets/create', data)
  },
  /**
   * 修改刊例基本信息
   * @param data
   * @returns {Promise}
   */
  savePublicationInfo(data) {
    return request.post('/plc/store', data)
  },
  /**
   * 删除刊例
   * @param id
   * @returns {Promise}
   */
  deletePublicationInfo(id) {
    let data = { id: id }
    return request.post('/plc/delete', data)
  },
  /**
   * 删除刊例明细
   * @param id
   * @returns {Promise}
   */
  deletePublicationItem(id) {
    let data = { id: id }
    return request.post('/plc_item/delete', data)
  },
  /**
   * 获取上月刊例
   * @param data
   * @returns {Promise}
   */
  getLastPublicationItem(data) {
    return request.post('/plc_item/get_last', data)
  },

  /**
   * getPublicationInfoItems
   * @description 刊例详情及其明细
   * @param {*} data
   * @returns
   */
  getPublicationInfoItems(data) {
    return request.post('/plc/get_info_items', data)
  },
  /**
   * 刊例调整列表
   * @param data
   * @returns {Promise}
   */
  getPublicationItemList(data) {
    return request.post('/plc_item/list', data)
  },
  /**
   * 一个刊例调整明细
   * @param id
   * @returns {Promise}
   */
  getPublicationItemInfo(id) {
    let data = { id: id }
    return request.post('/plc_item/get_info', data)
  },
  /**
   * 保存刊例调整
   */
  savePublicationItem(data) {
    return request.post('/plc_item/store', data)
  },

  /**
   * 简单保存刊例提报信息
   * @param id
   * @param formMap formMap {k1:v1,k2:v2, ...}
   * @returns {Promise}
   */
  sampleSavePublicationItem(id, formMap) {

    return request.post('/plc_item/sample_edit', { id, 'kv': formMap })
  },
  /**
   * 保存刊例调整
   */
  saveQuoteItem(data) {
    return request.post('/plc_item/save_quote', data)
  },
  /**
   * 保存-基本信息
   * @param data
   * @returns {Promise}
   */
  saveBasePublicationItem(data) {
    return request.post('/plc_item/save_base', data)
  },
  /**
   * 申请修改刊例价格
   */
  applyEditPricePublicationItem(data) {
    return request.post('/plc_item/apply_edit_price', data)
  },
  /**
   *
   * 获取报价配置
   * @param data
   * @returns {Promise}
   */
  getPublicationSetting(data) {
    return request.post('/plc/get_setting', data)
  },
  /**
   * 红人某平台截至某月的实时数据
   * @param data
   * @returns {Promise}
   */
  getPublicationCurrentData(data) {
    return request.post('/plc/get_current_data', data)
  },
  /**
   * 获取广告订单数
   * @param data
   * @returns {Promise}
   */
  getPublicationOrderTotal(data) {
    return request.post('/plc/get_order_total', data)
  },

  /**
   * 保存终价
   * @param data
   * @returns {Promise}
   */
  saveRealQuote(data) {
    return request.post('/plc_item/save_real_quote', data)
  },
  /**
   * 保存-刊例提报
   * @param data
   * @returns {Promise}
   */
  savePlcItem(data) {
    return request.post('/plc_item/save_item', data)
  },

  /**
   * 获取红人粉丝信息
   * @param data
   * @returns {Promise}
   */
  getFansData(data) {
    return request.post('/plc_item/get_fans_data', data)
  },
  /**
   * getPublicationList
   * @description 月刊例列表列表
   * @param {*} data
   * @returns
   */
  getPublicationMonthList(data) {
    return request.post('/plc_month/list', data)
  },
  /**
   * 月刊详情
   * @param id
   * @returns {Promise}
   */
  getPublicationMonthDetail(id) {
    return request.post('/plc_month/detail', { id: id })
  },
  /**
   * 保存月刊基本信息及各平台基本刊例信息
   * @param data
   * @returns {Promise}
   */
  savePublicationMonthInfo(data) {
    return request.post('/plc_month/store', data)
  },
  /**
   * 保存月刊基本信息及各平台基本刊例信息
   * @param data
   * @returns {Promise}
   */
  savePublicationCoverInfo(data) {
    return request.post('/plc_month/cover/store', data)
  },


  /**
   * 删除月刊
   * @param id
   * @returns {Promise}
   */
  delPublicationMonthInfo(id) {
    return request.post('/plc_month/delete', { id: id })
  },
  /**
   * 刊例申请改价记录
   * @param id
   * @returns {Promise}
   */
  getPlcItemApplyRecord(id) {
    return request.post('/plc_item/get_apply_records', { plc_id: id })
  },
  /**
   * 处理：刊例申请改价记录
   * @param data
   * @returns {Promise}
   */
  handlePlcItemApplyRecord(data) {
    return request.post('/plc_item/handle_apply_record', data)
  },
  /**
   * 通知定价
   * @returns {Promise}
   * @param id
   */
  handleRealQuoteNotify(id) {
    return request.post('/plc_item/real_quote_notify', { id: id })
  },
  /**
   * 红人平台刊例报价
   * @returns {Promise}
   * @param data
   */
  getQuotationData(data) {
    return request.post('/kol/quote_list', data)
  },
  getQuotationCharts(data) {
    return request.post('/kol/quote_charts', data)
  }
}
