export default {
    path: 'platform',
    component: () => import('@/pages/platform/PlatformList'),
    meta: {title: '平台管理'},
    name: 'platform',
    children: [
        {
            path: ':platform_id/plc-set',
            component: () => import( '@/pages/platform/PlcSet'),
            name: 'platform_plc_set',
            meta: {title:'刊例配置'},
        }
    ]
}
